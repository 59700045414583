@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Source+Sans+Pro:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Quicksand:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Condiment&family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rancho&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Google Font Import 
======================= */

/* Colors 
============== */

.cl1 {
    color: #f9004d !important;
}

.cl2 {
    color: #696969;
}

.cl3 {
    color: #fff !important;
}

/* Gradient 
============== */

.gradient-primary {
    background: -webkit-linear-gradient(145deg, #f81f01, #ee076e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Font Weight 
============== */

.f-lighter {
    font-weight: lighter;
}

/* Common CSS 
============== */

html,
body {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    position: relative;
    z-index: 1;
    background: #fff;
    color: #191919;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
    position: relative;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    margin: 0;
    color: #191919;
}

h6 {
    font-family: "Poppins", serif;
}

h6 a:hover {
    color: #f9004d;
}

a {
    text-decoration: none;
    cursor: pointer;
    font-family: "Poppins", serif;
}

p {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    position: relative;
    color: #686868;
}

button,
input,
textarea,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
    outline: none;
    outline-width: 0 !important;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

img {
    display: inline-block;
    max-width: 100%;
}

i,
a {
    display: inline-block;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

li {
    display: inline-block;
}

/* Section Padding CSS 
============== */

.section-padding {
    padding: 120px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .section-padding {
        padding: 80px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding {
        padding: 60px 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-padding {
        padding: 50px 0;
    }
}

@media (max-width: 575px) {
    .section-padding {
        padding: 50px 0;
    }
}

/* Margin & Padding CSS 
============== */

.pad6 {
    padding: 6% !important;
}

.pad-top-0 {
    padding-top: 0;
}

.pad-bot-0 {
    padding-bottom: 0;
}

.pad-top-20 {
    padding-top: 20px;
}

.pad-bot-20 {
    padding-bottom: 20px;
}

.pad-top-30 {
    padding-top: 30px;
}

.pad-bot-30 {
    padding-bottom: 30px;
}

.pad-top-40 {
    padding-top: 40px;
}

.pad-bot-40 {
    padding-bottom: 40px;
}

.pad-top-50 {
    padding-top: 50px;
}

.pad-bot-50 {
    padding-bottom: 50px;
}

.pad-bot-25 {
    padding-bottom: 25px;
}

.pad-lft-15 {
    padding-left: 15%;
}

.pad-rgt-15 {
    padding-right: 15%;
}

.mar-tp-30 {
    margin-top: 30px;
}

.mar-bt-30 {
    margin-bottom: 30px;
}

.mar-tp-50 {
    margin-top: 50px;
}

.mar-bt-50 {
    margin-bottom: 50px;
}

.mar-tp-100 {
    margin-top: 100px;
}

.mar-bt-100 {
    margin-bottom: 100px;
}


/* Pre-loader CSS Start
======================= */

.site-preloader-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #f9004d;
    z-index: 999;
}

.site-preloader-wrap .spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
}

.spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}


/* Section Title
================ */

.section-title {
    position: relative;
}

.section-title h2 {
    font-size: 50px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 1px;
    margin: 20px 0;
}

.section-title h2 b {
    color: #f9004d;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-title h2 {
        font-size: 40px;
        line-height: 55px;
        margin: 10px 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .section-title h2 {
        font-size: 35px;
        line-height: 45px;
        margin: 8px 0;
        margin-bottom: 15px;
    }
}

.section-title h5 {
    font-size: 18px;
    letter-spacing: 2px;
}

.section-title h6 {
    color: #f9004d;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Button Style
================ */

.main-button {
    display: inline-block;
    background: -webkit-linear-gradient(145deg, #f81f01, #ee076e);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
    padding: 14px 28px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 5px;
    transition: .3s;
}

.ebtn {
    background: -webkit-linear-gradient(145deg, #f81f01, #ee076e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.ebtn:hover {
    background: -webkit-linear-gradient(145deg, #000, #000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}



@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .main-button {
        font-size: 15px;
        padding: 12px 28px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .main-button {
        font-size: 14px;
        padding: 10px 25px;
    }
}

@media only screen and (max-width: 991px) {
    .main-button {
        display: none;
    }

    .main-button-show {
        display: inline-block;
    }
}

.main-button:hover {
    background: -webkit-linear-gradient(145deg, #ee076e, #f81f01);
    color: #fff !important;

}

/* Form Style CSS
================ */

input,
textarea,
select {
    width: 100%;
    background: transparent;
    border: 1px solid #ddd;
    padding: 15px 15px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: .3s;
    margin-bottom: 20px;
    border-radius: 5px;
}

textarea {
    height: 150px;
    resize: none;
}

input[type="submit"],
button[type="submit"] {
    border: none;
    display: inline-block;
    background: #f9004d;
    color: #fff;
    padding: 12px 20px;
    margin: 20px 0;
    width: auto;
    text-transform: uppercase;
    transition: .3s;
    cursor: pointer;
    border-radius: 7px;
    font-size: 15px;
    font-weight: 600;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    font-size: 15px;
    font-weight: 500;
    color: #191919;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
}

input::placeholder,
textarea::placeholder {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

/* Background Image
============== */
.single-slide-item {
    height: 90vh !important;
}
.single-slide-item1 {
    background-image: url(/static/media/environment.e0df35ff.jpg);
}
.single-slide-item2 {
    background-image: url(/static/media/galaryOldAge.b972f25d.JPG);
}
.single-slide-item3 {
    background-image: url(/static/media/health.7de9ddc8.JPG);
}
.single-slide-item4{
    background-image: url(/static/media/prize.3082c4b4.JPG);
}
.single-slide-item5 {
     background-image: url(/static/media/music.40fd2478.JPG);
 }
.single-slide-item6 {
    background-image: url(/static/media/run.c9d395f6.jpg);
}
.hero-area-bg-2 {
    background-image: url(/static/media/hero-area-bg-4.f54ced1b.jpg);
    height: 90vh !important;
}

.breadcroumb-area {
    background-image: url(/static/media/bread-bg.414b0866.jpg);
}


/* Header Area CSS 
================== */

.header-top-area {
    background: #f9004d;
    padding: 7px;
}

.header-top-area p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 0;
}


.header-area.absolate-header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
    padding: 10px 0;
    transition: .3s;
}

.header-area .is-sticky .sticky-area {
    z-index: 999 !important;
    -webkit-animation: slideInDown .6s forwards;
    animation: slideInDown .6s forwards;
    box-shadow: 0 3px 16px -2px #d0cece;
}

.header-area.theme-2 .is-sticky .sticky-area {
    background: #2f2e32fa;
    box-shadow: none;
}

.header-area .social i {
    color: inherit;
}

.header-top-area.absolate-header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    background: transparent;
    padding: 10px;
}

.header-top-area ul li a {
    color: #fff;
    font-size: 15px;
    margin-right: 8px;
    margin-top: 6px;
}

.header-top-area ul li a i {
    margin-right: 5px;
}

.social-icon i {
    color: #eee;
    font-size: 20px;
    padding: 7px;
}


/* Header Area 2 CSS*/

.header-area2.sticky-header2 {
    position: -webkit-sticky;
    position: sticky;
    background: #2f2e32fa;
    top: 0;
    width: 100%;
    z-index: 99;
    padding: 0px 30px;
    transition: .3s;
}

.header-area2 .is-sticky .sticky-area {
    z-index: 999 !important;
    -webkit-animation: slideInDown .6s forwards;
    animation: slideInDown .6s forwards;
    box-shadow: 0 3px 16px -2px #d0cece;
}

.header-area2.theme-2 .is-sticky .sticky-area {
    background: #2f2e32fa;
    box-shadow: none;
}

.header-area2 .social i {
    color: inherit;
}

.header-top-area.sticky-header {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    background: transparent;
    padding: 10px;
}

.header-top-area ul li a {
    color: #fff;
    font-size: 15px;
    margin-right: 8px;
    margin-top: 6px;
}

.header-top-area ul li a i {
    margin-right: 5px;
}

.social-icon i {
    color: #eee;
    font-size: 20px;
    padding: 7px;
}

/* Logo CSS 
=========== */

.logo {
    width: 80px;
    margin-top: 13px;
}

.logo img {
    -webkit-filter: none;
            filter: none;
}

/*Main Menu CSS 
=============== */

.header-area .navigation {
    padding: 0px 30px;
    background: #fff;
    height: 80px;
}

.header-area2 .navigation {
    background: #fff;
    height: 80px;
}


.main-menu .navbar {
    padding: 0;
}

.main-menu li.nav-item {
    position: relative;
    margin-right: 30px;
}

.main-menu li.nav-item:last-child {
    margin-right: 0;
}

.main-menu .navbar-nav .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    display: block;
    padding: 30px 0;
}

.main-menu .navbar-nav .nav-link.active {
    color: #f9004d;
}

.main-menu li.nav-item:hover>a {
    color: #F41D0A !important;
}

.header-area.theme-2 .navigation {
    background: transparent;
}

/* Scroll */
.header-area2.theme-2 .navigation {
    background: transparent;
}

.header-area.theme-2 .navigation ul li a {
    color: #fff;
}

/* Scroll */
.header-area2.theme-2 .navigation ul li a {
    color: #fff;
}

@media only screen and (max-width: 991px) {

    .item-show {
        display: block;
    }
}

@media only screen and (min-width: 992px) and (max-width: 3840px) {
    .item-show {
        display: none;
    }
}

/* Header Right Content CSS 
=========================== */

.header-area .main-button {
    margin-top: 14px;
}

/* Scroll */
.header-area2 .main-button {
    margin-top: 14px;
}

.header-area .social {
    padding: 0px;
    margin-top: 28px;
}

/* Scroll */
.header-area2 .social {
    padding: 0px;
    margin-top: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area .social {
        display: none;
    }

    /* Scroll */
    .header-area2 .social {
        display: none;
    }

}

@media only screen and (max-width: 991px) {

    /* Scroll */
    .header-area2.theme-2 .navigation ul li a {
        color: #000;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-area .social {
        display: none;
    }

    /* Scroll */
    .header-area2 .social {
        display: none;
    }
}

@media (max-width: 575px) {
    .header-area .social {
        display: none;
    }

    /* Scroll */
    .header-area2 .social {
        display: none;
    }
}

.header-area .social i {
    color: #fff;
}

/* Scroll */
.header-area2 .social i {
    color: #fff;
}

.header-area .social i:before {
    font-size: 20px;
    padding: 6px;
}

/* Scroll */
.header-area2 .social i:before {
    font-size: 20px;
    padding: 6px;
}

.header-area .social ul {
    margin: 15px 0;
    padding: 0;
}

/* Scroll */
.header-area2 .social ul {
    margin: 15px 0;
    padding: 0;
}


/* Hero Area CSS 
================ */

.hero-area {
    /* background-image: url(../src/Static/img/hero-bg.jpg); */
    width: 100%;
    height: 90vh;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-area::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #233F4D;
    opacity: .6;
    z-index: -1;
}

.hero-area-content b {
    background: -webkit-linear-gradient(145deg, #f81f01, #ee076e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-area .hero-area-content h5 {
    font-size: 15px;
    color: #191919;
    font-weight: normal;
}

.hero-area h6 {
    color: #f9004d;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.hero-area .hero-area-content h1 {
    font-size: 80px;
    font-weight: 700;
    line-height: 120px;
    margin: 30px 0;
    color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-area .hero-area-content h1 {
        font-size: 75px;
        line-height: 100px;
        margin: 25px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-area .hero-area-content h1 {
        font-size: 60px;
        line-height: 90px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero-area .hero-area-content h1 {
        font-size: 50px;
        line-height: 80px;
    }
}

@media (max-width: 575px) {
    .hero-area .hero-area-content h1 {
        font-size: 40px;
        line-height: 65px;
    }
}

/* Slider CSS 
==================*/

.single-slide-item {
    background-size: cover;
    background-position: center;
    background-color: #ddd;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.single-slide-item:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000000;
    opacity: 0.6;
    z-index: -1;
}

.homepage-slides .owl-nav button.owl-prev,
.homepage-slides .owl-nav button.owl-next {
    width: 50px;
    height: 50px;
    border: 2px solid;
    font-size: 20px;
    font-weight: 100;
    line-height: 40px;
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -25px;
    color: #f9004d;
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-out 0s;
}

.homepage-slides .owl-nav button.owl-next {
    left: auto;
    right: 15px;
}


.homepage-slides:hover .owl-nav button.owl-prev,
.homepage-slides:hover .owl-nav button.owl-next {
    visibility: visible;
    opacity: .3;
}

.homepage-slides .owl-nav button.owl-prev:hover,
.homepage-slides .owl-nav button.owl-next:hover {
    background: -webkit-linear-gradient(145deg, #f81f01, #ee076e);
    color: #fff;
    border-color: #F41D0A;
    opacity: 1;
}

.hero-area-content {
    display: table-cell;
    vertical-align: middle;
    padding-top: 7%;
}

.hero-area-content .main-button {
    margin: 25px 0;
}

.single-slide-item h5 {
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.single-slide-item h1 {
    color: #fff;
    font-size: 80px;
    font-weight: 700;
    line-height: 120px;
    letter-spacing: 1px;
    margin: 30px 0;
}

.social-area {
    display: inline-block;
}

.social-area a {
    color: #fff;
    font-size: 20px;
}

.social-area a i {
    margin: 10px;
}

/* About Area CSS 
============== */

.about-section {
    position: relative;
    background-color: #f4f4f4;
    background-size: cover;
    background-position: center center;
    padding: 50px 0 120px;
    background-image: url(/static/media/abt-bg-01.435ac982.jpg);
}

.about-section .content-column {
    position: relative;
    margin-bottom: 30px;
}

.about-section .content-column .inner-column {
    position: relative;
    padding-left: 60px;
}

.about-section .content-column .text {
    position: relative;
    font-size: 14px;
    line-height: 25px;
    color: #888888;
    font-weight: 400;
    margin-bottom: 20px;
}

.about-section .our-help {
    position: relative;
    max-width: 320px;
    margin-bottom: 30px;
}

.about-section .our-help ul li {
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
    min-width: 150px;
    padding-right: 10px;
    padding-left: 30px;
}

.about-section .our-help ul li:before {
    position: absolute;
    height: 1px;
    width: 20px;
    color: red;
    content: "✓";
    left: 0;
}

.abt-cor-img {
    border-bottom-right-radius: 30px;
    border-top-left-radius: 30px;
}

.abt-cor-img2 {
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
}

.about-section .our-help ul li a {
    display: inline-block;
    font-size: 14px;
    line-height: 26px;
    color: #222222;
    font-weight: 600;
    transition: all 300ms ease;
}

.about-section .our-help ul li a:hover {
    color: #F41D0A;
}

.about-section .image-column {
    margin-bottom: 30px;
}

.about-section .image-column .inner-column {
    position: relative;
    padding-top: 30px;
    padding-left: 65px;
}

.about-section .image-column .image-1 {
    position: relative;
    left: 0;
    z-index: 1;
}

.about-section .image-column .image-1 img {
    max-width: 100%;
    display: block;
    height: auto;
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, .2);
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, .2);
    box-shadow: 0 15px 30px rgba(0, 0, 0, .7);
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

sec-title {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 25px;
    z-index: 9;
}

.sec-title>.title {
    position: relative;
    font-size: 14px;
    line-height: 1.2em;
    color: #000;
    font-weight: 600;
    display: block;
    letter-spacing: 5px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.sec-title>h2 {
    font-family: 'Poppins', sans-serif;
    position: relative;
    line-height: 1.1em;
    font-size: 45px;
    color: #222222;
    font-weight: 600;
    letter-spacing: 2px;
}

.sec-title>h2 span {
    font-family: 'Rancho', cursive;
    font-weight: 400;
    color: #f9004d;
}

.sec-title.light>span,
.sec-title.light h2 {
    color: #ffffff;
}

.sec-title .separator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 80px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcc005', endColorstr='#ea7325', GradientType=1);
}

.sec-title .separator:after {
    position: absolute;
    right: -15px;
    height: 5px;
    width: 10px;
    content: "";
    background-color: #000;
}

.sec-title .separator-two {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 30px;
    height: 15px;
    width: 20px;
    border: 5px solid #ffffff;
    background: #000;
    border-radius: 3px;

}

.sec-title .separator-two:before,
.sec-title .separator-two:after {
    position: absolute;
    left: -35px;
    bottom: 0;
    height: 5px;
    width: 30px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcc005', endColorstr='#ea7325', GradientType=1);
    content: "";
}

.sec-title .separator-two:after {
    left: auto;
    right: -35px;
}

.sec-title.text-center .separator-two {
    left: 50%;
    margin-left: -5px;
}


@media only screen and (max-width: 768px) {
    .hero-area-content {
        padding-top: 25%;
    }
}

.about-content h5 {
    margin-bottom: 20px;
}

.about-content .main-btn {
    margin: 20px 0;
}

/* Section Padding CSS 
====================== */

.section-padding2 {
    padding: 2px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .section-padding2 {
        padding: 80px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding2 {
        padding: 60px 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-padding2 {
        padding: 50px 0;
    }
}

@media (max-width: 575px) {
    .section-padding2 {
        padding: 50px 0;
    }
}

/*Client CSS 
============ */

.client-area.section-padding {
    padding: 80px 0;
}

.single-logo-wrapper {
    width: 100%;
    height: 100px;
    display: table;
    opacity: .5;
    transition: .3s;
}

.logo-inner-item {
    display: table-cell;
    vertical-align: middle;
}

.single-logo-wrapper:hover {
    opacity: 1;
}

/* CTA Area CSS 
=============== */

.cta-area {
    background-size: cover;
    background-position: center;
    height: 450px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url(/static/media/donor.a10adea0.JPG);
}


.cta-area::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.6;
    z-index: -1;
}

.cta-area h2 {
    font-size: 55px;
    font-weight: 500;
    line-height: 80px;
    color: #FFF;
    text-align: center;
    z-index: 999;
}

.cta-area .main-button {
    margin-top: 30px;
}

.cta-area p {
    color: #fff;
}

.cta-area b {
    color: #f9004d;
}


/*Margin & Padding 
============== */

.pad-top-0 {
    padding-top: 0;
}

.pad-bot-0 {
    padding-bottom: 0;
}

.pad-top-20 {
    padding-top: 20px;
}

.pad-bot-20 {
    padding-bottom: 20px;
}

.pad-top-30 {
    padding-top: 30px;
}

.pad-bot-30 {
    padding-bottom: 30px;
}

.pad-top-40 {
    padding-top: 40px;
}

.pad-bot-40 {
    padding-bottom: 40px;
}

.pad-top-50 {
    padding-top: 50px;
}

.pad-bot-50 {
    padding-bottom: 50px;
}

.mar-tp-30 {
    margin-top: 30px;
}

.mar-bt-30 {
    margin-bottom: 30px;
}

.mar-tp-50 {
    margin-top: 50px;
}

.mar-bt-50 {
    margin-bottom: 50px;
}

.mar-tp-100 {
    margin-top: 100px;
}

.mar-bt-100 {
    margin-bottom: 100px;
}

/* What We Do CSS 
============== */

.what-we-do {
    position: relative;
    background-color: #f9f9f9;
}

.what-we-do .outer-container {
    position: relative;
    min-height: 250px;
}

.what-we-do .image-column {
    position: absolute;
    float: right;
    right: 0px;
    top: 0px;
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
    background-image: url(/static/media/whatWeDo.450beffd.jpg)
}

.what-we-do .image-column .image-box {
    position: relative;
    display: none;
}

.what-we-do .image-column .image-box img {
    position: relative;
    display: block;
    width: 100%;
}

.what-we-do .content-column {
    position: relative;
    float: left;
    width: 50%;
}

.what-we-do .content-column .inner-box {
    position: relative;
    max-width: 600px;
    float: right;
    padding: 150px 15px 130px;
    padding-right: 25px;
}

.what-we-do .content-column .inner-box .sec-title {
    margin-bottom: 30px;
}

.what-we-do .content-column .inner-box .text {
    position: relative;
    margin-bottom: 30px;
}

.what-we-do .content-column .inner-box .text p {
    position: relative;
    font-size: 14px;
    line-height: 25px;
    color: #888888;
    margin-bottom: 20px;
}

.what-we-do .content-column .inner-box .text p:last-child {
    margin-bottom: 0px;
}

.what-we-do .content-column .inner-box .choose-info {
    position: relative;
}

.choose-info {
    position: relative;
}

.choose-info li {
    position: relative;
    padding-left: 80px;
    margin-bottom: 25px;
}

.choose-info li .icon {
    position: absolute;
    display: inline-block;
    line-height: 1em;
    left: 0;
    top: 0;
    font-size: 50px;
    color: #c8c8c8;
    transition: all 300ms ease;
}

.choose-info li:hover .icon {
    color: #F41D0A;
}

.choose-info li h3 {
    display: block;
    font-size: 18px;
    line-height: 1.2em;
    color: #222222;
    font-weight: 500;
    margin-bottom: 5px;
}

.choose-info li h3 a {
    color: #f9004d;
    transition: all 300ms ease;
}

.choose-info li h3 a:hover {
    color: #f81f01;
}

.choose-info li p {
    position: relative;
    font-size: 14px;
    line-height: 25px;
    color: #888888;
    font-weight: 400;
}

@media only screen and (max-width: 768px) {

    .what-we-do .content-column .inner-box {
        padding: 50px 35px 50px;
    }

    .sidebar-page-container {
        padding: 50px 0 50px !important;
    }

    .mr0 {
        margin: 0;
    }
}

/* Recent Event CSS 
=================== */

.sidebar-page-container {
    position: relative;
    padding: 150px 0 140px;
}

.sidebar-page-container .sidebar-side,
.sidebar-page-container .content-side {
    position: relative;
    margin-bottom: 50px;
}

.sidebar-widget {
    position: relative;
    margin-bottom: 50px;
}

.sidebar-side .sidebar-widget:last-child {
    margin-bottom: 0;
}

.sidebar-page-container .sidebar-title {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;
}

.sidebar-page-container .sidebar-title:after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 45px;
    background-color: #f9004d;
    content: "";
}

.sidebar-page-container .sidebar-title h3 {
    position: relative;
    font-size: 20px;
    color: #141414;
    line-height: 30px;
    font-weight: 500;
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.event-grid {
    position: relative;
    padding-right: 5px;
}

.event-block-two {
    position: relative;
    margin-bottom: 45px;
    z-index: 1;
}

.event-block-two .inner-box {
    position: relative;
}

.event-block-two .image-box {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.event-block-two .image-box img {
    display: block;
    width: 100%;
    border-radius: 10px;
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1);
    -o-filter: grayscale(1);
    filter: grayscale(1);
    transition: all 700ms ease;
}

.event-block-two .inner-box:hover .image-box img {
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    -o-filter: grayscale(0);
    filter: grayscale(0);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.event-block-two .image-box .date {
    position: absolute;
    z-index: 99;
    left: 25px;
    top: 35px;
    font-size: 15px;
    line-height: 1.2em;
    color: #ffffff;
    font-weight: 600;
    padding-top: 10px;
    text-transform: uppercase;
}

.event-block-two .image-box .date span {
    position: relative;
    display: inline-block;
    font-size: 25px;
    line-height: 1em;
    color: #ffffff;
}

.event-block-two .image-box .date:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    width: 50px;
    background: #ffffff;
    content: "";
}

.event-block-two .content-box {
    position: relative;
    padding-top: 20px;
}

.event-block-two .content-box h4 {
    display: block;
    font-size: 18px;
    line-height: 30px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 10px;
}

.event-block-two .content-box h4 a {
    color: #333333;
    transition: all 300ms ease;
}

.event-block-two .content-box h4 a:hover {
    color: #F41D0A;
}

.event-block-two .content-box .info {
    position: relative;
    margin-bottom: 15px;
}

.event-block-two .content-box .info li {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 400;
    margin-right: 10px;
}

.event-block-two .content-box .info li a {
    color: #333333;
    transition: all 300ms ease;
}

.event-block-two .content-box .info li a i {
    color: #F41D0A;
    margin-right: 7px;
}

.event-block-two .content-box .info li a:hover {
    color: #F41D0A;
}

.event-block-two .content-box p {
    font-size: 14px;
    line-height: 25px;
    color: #888888;
    font-weight: 400;
}

.event-block-two .content-box .detail {
    position: relative;
    margin-bottom: 40px;
}

.event-block-two .content-box .detail li {
    position: relative;
    float: left;
    width: 50%;
    font-size: 14px;
    line-height: 20px;
    color: #888888;
    font-weight: 400;
    margin-bottom: 18px;
}

.event-block-two .content-box .detail li span {
    position: relative;
    display: inline-block;
    color: #333333;
    margin-right: 5px;
}

.event-block-two .content-box .detail li:last-child {
    margin-bottom: 0;
}

.event-grid .styled-pagination {
    margin-top: 30px;
}

/* breadcroumb CSS 
================== */

.breadcroumb-area.bread-bg-2 {
    /* background-image: url(../src/Static/img/bread-bg-5.jpg); */
}

.breadcroumb-area {
    height: 220px;
    background-size: cover;
    background-position: center bottom;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.breadcroumb-area:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000000;
    opacity: .7;
    z-index: -1;
}

.breadcroumb-area .breadcroumb-title h1 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fff;
    margin-bottom: 7px;
    margin-top: 45px;
}

.breadcroumb-area h6 a {
    color: #fff;
}

.breadcroumb-area h6 {
    color: #f9004d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcroumb-area .breadcroumb-title h1 {
        font-size: 50px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .breadcroumb-area .breadcroumb-title h1 {
        font-size: 40px;
    }
}

@media (max-width: 575px) {
    .breadcroumb-area .breadcroumb-title h1 {
        font-size: 35px;
    }
}

/* Events CSS* 
============== */
section.section {
    position: relative;
    z-index: 0;
    padding: 70px 0
}

@media only screen and (min-width:768px) {
    section.section {
        padding: 90px 0
    }
}

@media only screen and (min-width:992px) {
    section.section {
        padding: 120px 0
    }
}

@media only screen and (min-width:1200px) {
    section.section {
        padding: 140px 0
    }
}

.events-inner {
    background-color: #f9f7f6;
    overflow: hidden
}

.events-inner__bg {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    pointer-events: none
}

.events-inner .pagination {
    margin-top: 70px
}

.events-inner {
    background-color: #f9f7f6;
    overflow: hidden
}

.events-inner__bg {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    pointer-events: none
}

.events-inner .pagination {
    margin-top: 70px
}

.offset-30 {
    margin-bottom: -30px
}

.offset-xl-1 {
    margin-left: 8.333333%
}

.align-items-center {
    align-items: center !important
}

.img--bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    font-family: "object-fit: cover; object-position: top center";
    z-index: -1
}

.event_a {
    color: #f9004d;
    text-decoration: none;
    cursor: pointer;
    font-family: "Quicksand", serif;
    font-size: 20px;
    font-weight: 500;
}

.event_a:hover {
    color: #696969;
}

/* Footer Area CSS 
================== */

.footer-area {
    background: #2f2e32fa;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1
}

.footer-area .logo .navbar-brand {
    color: #fff;
}

.footer-area h5 {
    margin: 20px 0;
    color: #f9004d;
}

.footer-area p {
    color: #eee;
    margin-top: 20px;
}

.footer-area a {
    text-decoration: none;
    cursor: pointer;
    font-family: "Quicksand", serif;
}

.footer-area a:hover {
    color: #F41D0A;
}

.footer-area ul li a {
    display: block;
    color: #eee;
    padding: 7px 0;
}

.footer-area ul li a:hover {
    color: #F41D0A;
}

.footer-up {
    padding: 80px 0;
}

.footer-bottom {
    padding: 20px 0;
    border-top: 1px solid #898b8e;
}

p.privacy {
    text-align: right;
}

.contact-info a {
    color: #fff;
}

/* Return to Top CSS 
==================== */

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #f3004ed1 !important;
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    border-radius: 35px;
    display: none;
    transition: all 0.3s ease;
    z-index: 1;
}

#return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    transition: all 0.3s ease;
}

#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
    color: #fff;
    top: 5px;
}


/* event card CSS 
================= */

.event-card {
    margin-top: 20px;
    background-image: url(/static/media/abt-bg-01.435ac982.jpg)
}

.event-card .btn {
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 7px 20px;
}

.event-card .card-img-block {
    width: 91%;
    margin: 0 auto;
    position: relative;
    top: -20px;

}

.card-img-block a {
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: -5px;

}

.event-card .card-img-block img {
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.63);

}

.event-card h5 {
    color: #f9004d;
    font-weight: 600;
}

.event-card p {
    font-size: 14px;
    line-height: 25px;
    color: #888888;
    font-weight: 400;
}

.event-card .btn-primary {
    background-color: #4E5E30;
    border-color: #4E5E30;
}

.ecard {
    box-shadow: 0px 3px 20px 2px rgba(0, 0, 0, 0.25);
    border: none;
}

.event-container {
    padding: 50px 0 50px !important;
}

/* Single event 
================= */

.post-widget {
    position: relative;
}

.post-widget .post {
    position: relative;
    padding-left: 125px;
    margin-bottom: 30px;
    padding-top: 10px;
    padding-bottom: 30px;
    min-height: 130px;
    border-bottom: 1px solid #e3e3e3;
}

.post-widget .post:last-child {
    margin-bottom: 0px;
    border-bottom: 0;
    padding-bottom: 0;
}

.post-widget .post .thumb {
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    width: 105px;
    border-radius: 5px;
    overflow: hidden;
}

.post-widget .post .thumb img {
    display: block;
    width: 100%;
}

.post-widget .post h3 {
    position: relative;
    font-size: 18px;
    line-height: 25px;
    color: #141414;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
}

.post-widget .post h3 a {
    color: #141414;
    transition: all 300ms ease;
}

.post-widget .post:hover h3 a {
    color: #f9004d;
}

.post-widget .post .date {
    position: relative;
    font-size: 13px;
    line-height: 1.2em;
    color: #888888;
    font-weight: 300;
}

.sidebar {
    box-shadow: 0 0 5px #eee;
    padding: 15px 20px;
}

.recent-post h5 {
    margin: 30px 0;
    margin-top: 50px;
    font-weight: 600;
}

.recent-post img {
    float: left;
    width: 80px;
    height: 80px;
}

.recent-post h5 {
    margin: 30px 0;
    margin-top: 10px;
    font-weight: 600;
}

.recent-post img {
    float: left;
    width: 80px;
    height: 80px;
}

.single-recent-post h6 {
    margin: 0;
}

.single-recent-post {
    padding-bottom: 25px;
}

.single-recent-post .recent-post-content {
    display: block;
    padding-left: 110px;
}

.single-recent-post h6 a {
    color: #f9004d;
    line-height: 1.5;
}

.popular-tag h5 {
    margin: 30px 0;
    margin-top: 50px;
    font-weight: 600;
}

.popular-tag span {
    padding: 8px 15px;
    background: #ddd;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 15px;
}

.popular-tag a {
    color: #191919;
    padding: 5px;
}

.popular-tag span:hover {
    background: #f9004d;
}

.popular-tag span:hover a {
    color: #fff;
}

.single-comments-section p span {
    padding-left: 50px;
    font-size: 15px;
}

.event-details img {
    border-radius: 10px;
}

.event-details p.event-meta {
    color: #f9004d;
    font-size: 18px;
}

.event-details h6 {
    font-size: 24px;
    margin-top: 20px;
}

.event-details .event-date {
    background: #f9004d;
    display: inline-block;
    padding: 7px 12px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: -10px;
    right: 15px;
    border-radius: 0 10px 0 0;
}

.event-details ul li {
    color: #565656;
    padding-left: 30px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    position: relative;
}

.event-details ul li:before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #f9004d;
    border-radius: 50%;
    top: 9px;
    left: 0;
}

.event-details h5 {
    margin: 25px 0;
}

.map-section i {
    font-size: 50px;
    color: #f9004d;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
}

p.event-date {
    color: #f9004d;
    font-weight: 600;
}

.map-section iframe {
    -webkit-filter: grayscale(90%);
            filter: grayscale(90%);
    width: 100%;
}

/* Event Categories 
=================== */

.about-us-content-area h5 {
    margin: 25px 0;
}

.service-area {
    border-bottom: 1px solid #eee;
}

.single-service-item {
    background: #fff;
    padding: 30px 15px 0px 15px;
    text-align: center;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    transition: all 0.4s ease;
    border-radius: 7px;
}


.single-service-item h5 {
    font-size: 22px;
    font-weight: 600;
    margin: 35px 0;
    position: relative;
}

.single-service-item h5:after {
    position: absolute;
    content: "";
    width: 70px;
    height: 3px;
    bottom: -21px;
    left: 50%;
    margin-left: -35px;
    background: #f9004d;
}

.single-service-item p {
    margin: 40px 0;
}

.single-service-item i::before {
    font-size: 50px;
    color: #000;
}

.single-service-item:hover {
    box-shadow: 0px 3px 20px 2px rgba(0, 0, 0, 0.15);
    color: #000;
}

.single-service-item.active {
    box-shadow: 0px 3px 20px 2px rgba(0, 0, 0, 0.15);
    color: #000;
}

.single-service-item.active p {
    color: #000;
}

.single-service-item.active i:before {
    background: -webkit-linear-gradient(145deg, #f81f01, #ee076e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.single-service-item.active h5::after {
    background: #000;
}

/* Our Work 
=========== */

.corner img {

    border-radius: 20px 20px 20px 20px !important;
    height: 520px;
    object-fit: cover;
}

.shadow {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.7);
    border-radius: 20px 20px 20px 20px !important;
}

.single-program-item {
    color: #fff;
    padding: 15px;
    text-align: center;
    margin-top: 30px;
    position: relative;
    z-index: 1;
    transition: all 0.4s ease;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 40px;
    height: auto;
    widows: 100%;
    background: -webkit-linear-gradient(145deg, #f81f01, #ee076e);
}

.single-program-item h5 {
    font-size: 24px;
    font-weight: 600;
    position: relative;
    margin-bottom: 28px;
    margin-top: 10px;
}

.single-program-item p {
    color: #fff;
}

.single-program-item h5:after {
    position: absolute;
    content: "";
    width: 70px;
    height: 3px;
    bottom: -18px;
    left: 50%;
    margin-left: -35px;
    background: #fff;
}

.single-program-item i::before {
    font-size: 60px;
    color: #fff;
}


.about-us-content-area h5 {
    margin: 25px 0;
}

.program-area img {
    margin-bottom: 30px;
}

.single-program-item i {
    position: relative;
    z-index: 1;
}

.program-area.theme-3 img {
    border-radius: 10px;
}

.program-area.theme-3 h6 {
    margin-top: 40px;
}

.single-program-item:before {
    background-image: url(/static/media/about-img-1.bd2f2277.jpg);
}

.single-program-item:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
}

.single-program-item:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -webkit-linear-gradient(145deg, #ee076e, #f81f01);
    border-radius: 15px;
    z-index: -1;
    opacity: 1;
    visibility: hidden;
    transition: .3s;

}

.single-program-item:hover::before {
    visibility: visible;
    opacity: 1;
}

.single-program-item:hover::after {
    visibility: visible;
    opacity: .8;
}

/* Gallery 
========== */

ul.port-menu {
    list-style: none;
    padding: 0;
    margin-top: -20px;
}

ul.port-menu.recent {
    float: none;
}

ul.port-menu li {
    display: inline-block;
    padding: 20px;
    color: #191919;
    cursor: pointer;
    font-size: 17px;
    font-weight: 600;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    ul.port-menu li {
        padding: 10px;
    }
}

@media (max-width: 575px) {
    ul.port-menu li {
        padding: 10px;
    }
}

.port-menu li.active {
    color: #f9004d;
}

.single-portfolio-item {
    background-image: url(/static/media/marathonG1.8c3b794e.jpg);
}

.port-bg-1{
    background-image: url(/static/media/marathonG1.8c3b794e.jpg);
}

.port-bg-2 {
    background-image: url(/static/media/marathonG2.1ee8f40c.jpg);
}

.port-bg-3 {
    background-image: url(/static/media/marathonG3.d76807f5.jpg);
}

.port-bg-4 {
    background-image: url(/static/media/marathong4.cce3e67c.jpg);
}

 .port-bg-5 {
    background-image: url(/static/media/marathong5.5157c907.jpg);
}

.port-bg-6 {
    background-image: url(/static/media/marathong6.07230856.jpg);
}

/* .port-bg-7 {
    background-image: url(../src/Static/img/port-bg-7.jpg);
} */

.portfolio-list {
    overflow: hidden;
    margin-left: -1%;
    margin-top: 20px;
}

.single-portfolio-item {
    float: left;
    width: 32.33%;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-color: #191919;
    position: relative;
    margin-left: 1%;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-portfolio-item {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .single-portfolio-item {
        width: 100%;
    }
}

.single-portfolio-item.large {
    height: 610px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-portfolio-item.large {
        height: 300px;
    }
}

@media (max-width: 575px) {
    .single-portfolio-item.large {
        height: 300px;
    }
}

.single-portfolio-item.wide {
    width: 65.55%;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-portfolio-item.wide {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .single-portfolio-item.wide {
        width: 100%;
    }
}

.single-portfolio-item .details {
    background: #f9004d99;
    width: 100%;
    height: 100%;
    padding: 50px 35px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
}

.single-portfolio-item .info {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    margin-top: -20px;
    transition: all 0.5s;
}

.portfolio-list .details i {
    font-size: 25px;
}

.single-portfolio-item .info a:hover {
    color: #f9004d;
}

.single-portfolio-item .info a {
    color: #FFF;
}

.single-portfolio-item h5 {
    margin-bottom: 10px;
}

.single-portfolio-item:hover .details {
    opacity: 1;
    visibility: visible;
    top: 0;
}

.single-portfolio-item:hover .details .info {
    opacity: 1;
    margin-top: 0;
    transition-delay: 0.3s;
}

.portfolio-list.recent {
    margin-top: 0;
}

ul.port-menu.recent {
    list-style: none;
    padding: 0;
    bottom: 0;
    text-align: center;
    position: inherit;
}

.portfolio-single-section h3 {
    margin: 30px 0;
}

.project-info {
    border: 1px solid #ddd;
    padding: 30px;
}

.project-info h5 {
    margin-bottom: 40px;
}

.project-info p {
    margin: 25px 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.project-info p b {
    color: #191919;
}

.project-info span {
    float: right;
}

.project-info .main-btn {
    padding: 12px 18px;
    font-size: 15px;
    margin: 20px 0;
    width: 60%;
    margin-left: 20%;
}

.project-overview h5 {
    margin: 30px 0;
}

.project-overview p:last-child {
    margin-bottom: 40px;
}

.port-img img {
    min-width: 540px;
    min-height: 540px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .port-img img {
        min-width: 300px;
        min-height: 300px;
        margin-bottom: 30px;
    }
}

@media (max-width: 575px) {
    .port-img img {
        min-width: 200px;
        min-height: 200px;
        margin-bottom: 30px;
    }
}

/* tags 
======= */

.tags-widget {
    position: relative;
}

.tag-list {
    position: relative;
    display: block;
}

.tag-list li {
    position: relative;
    float: left;
    margin-right: 9px;
    margin-bottom: 9px;
}

.tag-list li a {
    position: relative;
    display: block;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    font-weight: 500;
    text-align: center;
    overflow: hidden;
    text-transform: uppercase;
    padding: 14px 35px;
    min-width: 85px;
    border: 1px solid #ededed;
    background-color: #69696929;
    border-radius: 5px;
    transition: all 300ms ease;
}

.tag-list li a:hover {
    color: #ffffff;
    background-color: #f9004d;
}

span.case-2-tag {
    border: 2px solid #ddd;
    display: inline-block;
    padding: 7px;
    font-size: 15px;
    text-transform: uppercase;
    margin: 20px 0;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 1px;
}

.case-2-img img {
    border-radius: 5px;
}

.case-2-texts h3 {
    font-size: 24px;
    margin: 18px 0;
}

.case-2-texts p {
    margin-bottom: 5px;
}

.case-2-texts .btn {
    background: #000;
    color: #fff;
    padding: 11px 27px;
    margin: 25px 0;
    letter-spacing: 1px;
}

a.line-btn {
    padding: 14px 28px;
    border-radius: 5px;
    color: #191919;
    margin: 20px 0;
    border: 2px solid #f9004d;
    transition: .3s;
    font-size: 16px;
    font-weight: 500;
}

a.line-btn:hover {
    background: #000;
    color: #fff;
}

.each-case-2 {
    border-radius: 6px;
    padding: 20px;
    transition: all .3s ease-in-out;
}

.each-case-2:hover {
    box-shadow: 0 3px 16px -2px #d0cece;
}

.progress-bar-area {
    margin-top: 20px;
}

.single-bar-item .barfiller {
    width: 95%;
    height: 10px;
    background: #ddd;
    border: none;
    box-shadow: none;
    margin-bottom: 10px;
}

.single-bar-item .barfiller .tip {
    font-weight: 600;
    font-size: 16px;
    background: none;
    color: #191919;
}


/* Heartbeat Animation 
====================== */

.heart {
    font-size: 150px;
    color: #e00;
    -webkit-animation: beat 2s infinite alternate;
            animation: beat 2s infinite alternate;
    background-position: 0 0;
    background-repeat: no-repeat;
}

@-webkit-keyframes beat {
    0% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1)
    }

    50% {
        -webkit-transform: scale(0.9, 0.9);
                transform: scale(0.9, 0.9)
    }

    100% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1)
    }
}

@keyframes beat {
    0% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1)
    }

    50% {
        -webkit-transform: scale(0.9, 0.9);
                transform: scale(0.9, 0.9)
    }

    100% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1)
    }
}


/* Achivement counter 
==================== */

.achievement-area {
    background-image: url(/static/media/impact.1cfa3465.jpg);
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.achievement-area::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.6;
    z-index: -1;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .achievement-area {
        height: 250px;
    }

}

@media (max-width: 575px) {
    .achievement-area {
        height: 400px;
    }
}

.single-counter-box {
    text-align: center;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-counter-box {
        margin-bottom: 30px;
    }

    .achievement-area {
        height: 400px;
    }
}

.single-counter-box h6 {
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    padding-top: 15px;
}

.single-counter-box h6:before {
    position: absolute;
    content: "";
    width: 40px;
    height: 3px;
    top: -8px;
    left: 50%;
    background: #f9004d;
    margin-left: -20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-counter-box h6 {
        font-size: 15px;
    }
}

@media (max-width: 575px) {
    .single-counter-box h6 {
        margin-bottom: 30px;
    }
}

@media (max-width: 575px) {
    .single-counter-box p {
        margin: 0;
    }
}


.single-counter-box p span {
    font-size: 50px;
    color: #fff;
    font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-counter-box p span {
        font-size: 40px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-counter-box p span {
        font-size: 40px;
    }
}

@media (max-width: 575px) {
    .single-counter-box p span {
        font-size: 30px;
    }
}

.skill-content-area {
    padding: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .skill-content-area {
        padding: 0;
        padding-left: 30px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .progress-bar-area {
        margin-top: 30px;
    }
}

.progress-bar-area h6 {
    margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .progress-bar-area h6 {
        margin-bottom: 5px;
    }
}

/* Contact Page CSS 
============== */

.contact-section h5 {
    margin: 30px 0;
}

.contact-detail i {
    font-size: 24px;
    color: #f9004d;
}

.contact-detail {
    margin-top: 40px;
}

.contact-detail p b {
    font-size: 18px;
    color: #f9004d;
    padding: 10px;
}

.contact-detail span {
    display: block;
    font-size: 15px;
}

.contact-form {
    margin-top: 50px;
}

.contact-form input::-webkit-input-placeholder {

    color: #000;

}

.contact-form input::placeholder {

    color: #000;

}

.contact-form h3 {
    margin: 30px 0;
    color: #f9004d;
}

.map-section iframe {
    -webkit-filter: grayscale(90%);
            filter: grayscale(90%);
    width: 100%;
}

.map-padding {

    padding-top: 50px;
}

/* Volunteer Section CSS 
=================== */

.team-area {
    border-top: 1px solid #eee;
}

.team-member-bg {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 450px;
    border-radius: 10px;
}

.team-member-small {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 250px;
    border-radius: 10px;
}
.team-member-small2 {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 250px;
    border-radius: 10px;
}
.single-team-member {
    position: relative;
    margin-top: 25px;
}

.single-team-member img {
    -webkit-filter: grayscale(.5);
            filter: grayscale(.5);
}

.single-team-member .team-content {
    position: absolute;
    bottom: -31px;
    left: 0;
    right: 0;
    background: #fff;
    text-align: center;
    transition: all .3s ease-out;
}

.team-content .team-title {
    font-weight: 500;
    margin-bottom: -3px;
}

.team-content .team-title a {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #191919;
    transition: all .3s ease-out;
    margin: 16px 0 5px;
}

.team-content .team-subtitle {
    font-size: 14px;
    color: #7c7c7c;
    margin-bottom: 5px;
}

ul.team-social {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.team-social li {
    display: inline-block;
}

ul.team-social li a {
    color: #f9004d;
    font-size: 18px;
    padding: 10px;
    transition: all .3s ease-out;
}

.single-team-member:hover .team-social li a {
    color: #fff;
}

.single-team-member:hover .team-content {
    bottom: 0;
    background: -webkit-linear-gradient(145deg, #f81f01, #ee076e);
}

.single-team-member:hover .team-content .team-title a {
    color: #fff;
}

.single-team-member:hover .team-content .team-subtitle p {
    color: #fff;
    margin: 0;
}

.team-member-bg {
    background-image: url(/static/media/volunteer-1.fbfdc3ae.jpg);
}
.team-member-small {
    background-image: url(/static/media/volunteer-1.fbfdc3ae.jpg);
}
.team-member-small2 {
    background-image: url(/static/media/volunteer-2.b004e512.jpg);
}
.volunteer-bg-2 {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 450px;
    border-radius: 10px;
}

.volunteer-bg-2 {
    background-image: url(/static/media/volunteer-2.b004e512.jpg);
}

.volunteer-bg-3 {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 450px;
    border-radius: 10px;
}

.volunteer-bg-3 {
    background-image: url(/static/media/volunteer-3.8d1872ad.jpg);
}

/* About Page Section CSS 
=================== */

.my-skills {
    background-color: #f9f9f9;
    position: relative;
}


.info-skills {
    text-align: left;
    margin-right: 10px;
    margin-bottom: 30px;
    position: relative;
    margin-left: 10px;
}

.info-skills h3 {
    font-size: 22px;
    text-transform: capitalize;
    margin-bottom: 30px;
    color: #444;
    position: relative;
    padding-left: 40px;
    font-weight: 600;
    letter-spacing: 1px;
}

.info-skills h3::before {
    position: absolute;
    left: 0;
    bottom: 12px;
    background: #444;
    height: 1px;
    width: 30px;
    display: block;
    content: " ";
}

.my-resume {
    padding-left: 30px;
    margin-right: 15px;
    border-left: 2px solid #cfcfcf;
    margin-left: 15px;
}

/* section skills */
.skills .skill-box {
    margin-bottom: 30px;
}

.skills .skill-box:last-of-type {
    margin-bottom: 0;
}

.skills .skill-title {
    margin-bottom: 10px;
    color: #444;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
}

.skills .skill-box .progress-line {
    background: #ddd;
    height: 5px;
    margin: 0;
    position: relative;
    width: 100%;
}

.skills .skill-box .progress-line>span {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: #f3c26b;
}

.skills .skill-box .progress-line>span>.percent-tooltip {
    position: absolute;
    right: -15px;
    top: -30px;
    margin-bottom: 10px;
    color: #444;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.img-svg img {
    padding-right: 40px;
}

.history-top {
    display: inline-block;
    text-align: center;
    padding: 10px 20px;
    font-weight: 500;
    line-height: normal;
    font-size: 12px;
    color: #444 !important;
    background: #f9f9f9;
    border-radius: 0px;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid #cfcfcf;
}

.history-top::before {
    position: absolute;
    width: 30px;
    height: 3px;
    background: #cfcfcf;
    left: -47px;
    top: 13px;
    display: block;
    content: "";
}

.title-resume {
    font-size: 22px;
    text-transform: capitalize;
    margin-bottom: 30px;
    color: #444;
    position: relative;
    padding-left: 40px;
    font-weight: 600;
    letter-spacing: 1px;
}

.title-resume::before {
    position: absolute;
    left: 0;
    bottom: 12px;
    background: #444;
    height: 1px;
    width: 30px;
    display: block;
    content: " ";
}

.resume-item {
    background: #f9f9f9;
    border: 1px solid #f3f2f2;
    position: relative;
    margin-bottom: 30px;
    padding: 30px 20px;
    box-shadow: 0px 0px 24px 0px #00000042;
}

.resume-item .icon-bg {
    position: absolute;
    color: #9b9b9b;
    bottom: 20px;
    font-size: 50px;
    right: 20px;
    opacity: .1;
}

.resume-head {
    border-bottom: 2px solid #f3f2f2;
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
}

.icon-exp {
    height: 40px;
    width: 40px;
    background: #fff;
    background: -webkit-linear-gradient(145deg, #f81f01, #ee076e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    display: block;
    margin-right: 10px;
    float: left;
    /* border: 1px solid #f3f2f2; */
    border-radius: 3px;
}

.content-exper {}

.resume-head .content-exper h4 {
    color: #444;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 3px;
}

.resume-head.content-exper .history {
    font-size: 13px;
    color: #444 !important;
}

.resume-footer {
    position: relative;
}

.resume-footer p {
    margin-bottom: 0;
}

.img-resume {
    margin-top: 25px;
}

.padlr0 {

    padding-left: 0px !important;
    padding-right: 0px !important;
}

.bg1 {
    color: #fff !important;
    background-image: none !important;
}

/* Large Deveice: 1280px. */

@media only screen and (min-width: 992px) and (max-width: 1200px) {

    .header-top-area p {
        font-size: 13px;
    }

    .main-btn {
        font-size: 14px;
        padding: 14px 20px;
    }

    .theme-3 .main-btn {
        font-size: 13px;
    }

    .theme-3.header-area .main-btn {
        margin-top: 21px;
    }

    .theme-3.header-area .main-menu li.nav-item {
        margin-right: 21px;
    }

    .section-title h2 {
        font-size: 48px;
    }

    .header-area .main-btn {
        margin-top: 18px;
    }

    .logo {
        width: 170px;
        margin-top: 15px;
    }

    .theme-3 .logo {
        width: 160px;
        margin-top: 16px;
    }

    .theme-3 .section-title h2 {
        font-size: 44px;
    }

    .theme-3.cta-area h2 {
        font-size: 50px;
    }

    .cta-area.theme-2 h2 {
        font-size: 47px;
        line-height: 65px;
    }

    .main-menu .navbar-nav .nav-link {
        font-size: 14px;
    }

    .hero-area {
        height: 600px;
    }

    .single-service-item h5 {
        margin: 30px 0;
        font-size: 100%;
    }

    .case-2-texts h3 {
        font-size: 20px;
    }

    .case-2-texts p {
        font-size: 14px;
        line-height: 27px;
    }

    .team-desc i {
        top: 46%;
    }

    .single-team-item {
        padding: 40px;
    }

    .single-blog-item p {
        font-size: 14px;
        line-height: 25px;
    }

    .single-blog-item h5 {
        font-size: 17px;
    }

    .header-area .social {
        padding: 0;
        margin-top: 26px;
    }

    .single-feature-item h4 {
        font-size: 24px;
    }

    .hero-area.theme-3 .hero-area-content h1 {
        font-size: 65px;
        margin-top: 60px;
    }

    .about-us-bg {
        height: 400px;
        margin-top: 30px;
    }

    .single-recent-post h6 {
        font-size: 13px;
    }

    .about-content-area {
        padding: 25px;
    }

}

/* Tablet Layout: 768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .header-area .main-btn {
        display: none;
    }

    .main-menu .navbar-nav .nav-link {
        font-size: 15px;
        font-weight: 400;
        padding: 15px 0;
    }

    /*
  a.nav-link i {
      display: none;
  }
*/

    .navbar-toggler {
        padding: 5px 15px;
        font-size: 20px;
        line-height: 1;
        background-color: transparent;
        border: none;
        position: absolute;
        top: -45px;
        right: 0;
    }

    .navbar-toggler-icon {
        display: block;
        width: 30px;
        height: 2px;
        background-color: #f9004d;
        margin: 6px 0;
        transition: all 0.3s ease-out 0s;
    }

    .active .navbar-toggler-icon:nth-of-type(1) {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 7px;
    }

    .active .navbar-toggler-icon:nth-of-type(2) {
        opacity: 0;
    }

    .active .navbar-toggler-icon:nth-of-type(3) {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        position: absolute;
        top: 7px;
    }

    .main-menu .navbar .navbar-collapse {
        position: absolute;
        top: 13px;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 999;
        padding: 10px 16px;
        box-shadow: 0 26px 48px 0 rgba(0, 0, 0, 0.15);
    }


    .navigation .navbar .navbar-nav .nav-item .sub-nav-toggler {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 14px;
        font-size: 16px;
        background: none;
        border: 0;
        color: #094074;
    }

    .sub-menu {
        position: relative !important;
        width: 100% !important;
        left: 0 !important;
        top: auto !important;
        opacity: 1 !important;
        visibility: visible !important;
        display: none;
        right: auto;
        box-shadow: none;
        text-align: left;
        border-top: 0;
        transition: 0s;
    }

    .main-menu .sub-menu li a {
        font-size: 14px;
        display: block;
        padding: 12px;
        padding-left: 12px;
        color: #191919;
        transition: .3s;
        padding-left: 30px;
    }

    .main-menu .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none;
        outline: 0;
        cursor: pointer !important;
    }

    .sub-menu::after {
        display: none;
    }

    .section-title h2 {
        font-size: 42px;
    }

    .about-content {
        margin-top: 60px;
    }

    p.event-meta {
        font-size: 14px;
    }

    .header-area.theme-2 .navigation ul li a {
        color: #222;
    }

    .single-slide-item h1 {
        font-size: 60px;
        line-height: 100px;
    }

    .single-feature-item h4 {
        font-size: 18px;
    }

    .single-feature-item {
        padding: 20px;
    }

    .single-feature-item p {
        font-size: 15px;
    }

    .social-icon {
        position: absolute;
        top: -25px;
        right: 0;
    }

    .hero-area.theme-3 .hero-area-content h1 {
        font-size: 65px;
        margin-top: 60px;
    }

    .about-us-bg {
        height: 400px;
        margin-top: 30px;
    }

    .cta-area p {
        color: #fff;
        padding: 0 65px;
    }

    .hero-area.theme-3 .hero-area-content h1 {
        font-size: 55px;
        line-height: 80px;
    }

    .cta-area {
        height: 400px;
    }

    .cta-area h2 {
        font-size: 45px;
        line-height: 65px;
    }

    .cta-area.theme-2 h2 {
        font-size: 40px;
        line-height: 55px;
    }

    .cta-area.theme-2 .main-btn {
        margin-top: 35px;
        padding: 12px;
    }

    .single-process-area {
        margin-bottom: 50px;
    }

    .single-process-area {
        margin-bottom: 50px;
    }

    .section-bg .col-md-6 {
        padding-right: 0;
        padding-left: 0;
    }

    .single-testimonial-box {
        margin-bottom: 70px;
    }

    .single-service-item {
        margin-bottom: 40px;
    }

    .single-team-member {
        margin-bottom: 50px;
    }

    .single-blog-item {
        margin-bottom: 40px;
    }

    .about-image::after {
        width: 65%;
    }

    .testimonial-slider-bg {
        height: 500px;
    }

    .client-area.section-padding {
        padding: 50px 0;
    }


}

/* Mobile Layout: 320px. */

@media only screen and (max-width: 767px) {

    p {
        font-size: 16px;
    }

    .site-info {
        text-align: center;
    }

    .welcome-message {
        text-align: center;
    }

    .header-area .main-btn {
        display: none;
    }

    .event-container{
        padding: 0px 0 0px !important;
    }
    .social-icon {
        text-align: center;
    }

    .logo {
        width: 160px;
        margin-top: 16px;
    }

    .about-section {
        padding: 1px 0 1px;
    }

    .about-section-two .sec-title h2,
    .sec-title h2 {
        font-size: 25px;
        line-height: 1.2em;
    }

    .main-menu .navbar-nav .nav-link {
        font-size: 15px;
        font-weight: 400;
        padding: 15px 0;
    }

    .navbar-toggler {
        padding: 5px 15px;
        font-size: 20px;
        line-height: 1;
        background-color: transparent;
        border: none;
        position: absolute;
        top: -45px;
        right: 0;
    }

    .navbar-toggler-icon {
        display: block;
        width: 30px;
        height: 2px;
        background-color: #f9004d;
        margin: 6px 0;
        transition: all 0.3s ease-out 0s;
    }

    .active .navbar-toggler-icon:nth-of-type(1) {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 7px;
    }

    .active .navbar-toggler-icon:nth-of-type(2) {
        opacity: 0;
    }

    .active .navbar-toggler-icon:nth-of-type(3) {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        position: absolute;
        top: 7px;
    }

    .main-menu .navbar .navbar-collapse {
        position: absolute;
        top: 13px;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 999;
        padding: 10px 16px;
        box-shadow: 0 26px 48px 0 rgba(0, 0, 0, 0.15);
    }


    .navigation .navbar .navbar-nav .nav-item .sub-nav-toggler {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 14px;
        font-size: 16px;
        background: none;
        border: 0;
        color: #094074;
    }

    .sub-menu {
        position: relative !important;
        width: 100% !important;
        left: 0 !important;
        top: auto !important;
        opacity: 1 !important;
        visibility: visible !important;
        display: none;
        right: auto;
        box-shadow: none;
        text-align: left;
        border-top: 0;
        transition: 0s;
    }

    .main-menu .sub-menu li a {
        font-size: 14px;
        display: block;
        padding: 12px;
        padding-left: 12px;
        color: #191919;
        transition: .3s;
        padding-left: 30px;
    }

    .main-menu .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none;
        outline: 0;
        cursor: pointer !important;
    }

    .sub-menu::after {
        display: none;
    }

    .hero-area {
        height: 500px;
    }

    .hero-area h6 {
        font-size: 15px;
    }

    .about-content {
        margin-top: 60px;
    }

    .about-left {
        margin-left: 45px;
    }

    p.event-meta {
        font-size: 14px;
    }

    .cta-area h2 {
        font-size: 40px;
        line-height: 60px;
    }

    .main-btn {
        font-size: 14px;
        padding: 12px 18px;
    }

    .main-btn.btn-2 {
        font-size: 14px;
        padding: 12px 18px;
    }

    a.line-btn {
        font-size: 14px;
        padding: 12px 15px;
    }

    .achievement-area {
        height: 500px;
    }

    .single-counter-box h6::before {
        top: 4px;
    }

    .section-absolute-bg {
        display: none;
    }

    .section-bg .offset-6 {
        margin-left: 0;
    }

    .testimonial-slider-bg {
        height: 500px;
    }

    .single-team-item {
        padding: 7px 30px;
        margin-top: 20px;
    }

    .team-desc i {
        left: 35px;
    }

    .footer-area {
        text-align: center;
    }

    .footer-area .logo {
        width: 60%;
    }

    p.privacy {
        text-align: center;
        margin: 0;
    }

    .header-area.theme-2 .navigation ul li a {
        color: #222;
    }

    .single-slide-item h1 {
        font-size: 35px;
        line-height: 60px;
    }

    .single-feature-item h4 {
        font-size: 18px;
    }

    .single-feature-item {
        height: 250px;
        padding: 0;
        margin-bottom: 0;

    }

    .single-feature-item p {
        font-size: 15px;
    }

    .feature-area {
        padding-bottom: 50px;
    }

    .theme-2 .single-feature-item {
        padding: 35px;
        margin-bottom: 40px;
    }

    .social-icon {
        display: none;
    }

    .hero-area.theme-3 .hero-area-content h1 {
        font-size: 65px;
        margin-top: 60px;
    }

    .about-us-bg {
        height: 400px;
        margin-top: 30px;
    }


    .hero-area.theme-3 .hero-area-content h1 {
        font-size: 45px;
        line-height: 65px;
    }

    .cta-area {
        height: 290px;
    }

    .cta-area h2 {
        font-size: 18px;
        line-height: 50px;
    }

    .single-process-area {
        margin-bottom: 50px;
    }

    .single-process-area {
        margin-bottom: 50px;
    }

    .col-lg-12 {
        padding-left: 25px;
        padding-right: 25px;

    }

    .homepage-slides .owl-nav button.owl-prev,
    .homepage-slides .owl-nav button.owl-next {
        display: none;
    }

    .cta-area.theme-2 h2 {
        text-align: center;
    }

    .cta-area.theme-2 .main-btn {
        margin-top: 30px;
    }

    .client-area.section-padding {
        padding: 0;
    }

    .case-2-texts h3 {
        font-size: 22px;
    }

    .single-event h6 {
        font-size: 18px;
    }

    .single-event a {
        font-size: 16px;
    }

    .author-name {
        font-size: 18px;
    }

    .blog-content h5 a {
        font-size: 18px;
    }

    .single-blog-wrap h3 {
        font-size: 25px;
        margin-bottom: 22px;
        line-height: 40px;
    }

}

/* Wide Mobile Layout: 480px. */

@media only screen and (min-width: 576px) and (max-width: 767px) {

    .site-info {
        text-align: center;
    }

    .welcome-message {
        text-align: center;
    }

    .header-area .main-btn {
        display: none;
    }

    .social-icon {
        text-align: center;
    }

    .main-menu .navbar-nav .nav-link {
        font-size: 15px;
        font-weight: 400;
        padding: 15px 0;
    }

    .navbar-toggler {
        padding: 5px 15px;
        font-size: 20px;
        line-height: 1;
        background-color: transparent;
        border: none;
        position: absolute;
        top: -45px;
        right: 0;
    }

    .navbar-toggler-icon {
        display: block;
        width: 30px;
        height: 2px;
        background-color: #f9004d;
        margin: 6px 0;
        transition: all 0.3s ease-out 0s;
    }

    .active .navbar-toggler-icon:nth-of-type(1) {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 7px;
    }

    .active .navbar-toggler-icon:nth-of-type(2) {
        opacity: 0;
    }

    .active .navbar-toggler-icon:nth-of-type(3) {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        position: absolute;
        top: 7px;
    }

    .main-menu .navbar .navbar-collapse {
        position: absolute;
        top: 13px;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 999;
        padding: 10px 16px;
        box-shadow: 0 26px 48px 0 rgba(0, 0, 0, 0.15);
    }


    .navigation .navbar .navbar-nav .nav-item .sub-nav-toggler {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 14px;
        font-size: 16px;
        background: none;
        border: 0;
        color: #094074;
    }

    .sub-menu {
        position: relative !important;
        width: 100% !important;
        left: 0 !important;
        top: auto !important;
        opacity: 1 !important;
        visibility: visible !important;
        display: none;
        right: auto;
        box-shadow: none;
        text-align: left;
        border-top: 0;
        transition: 0s;
    }

    .main-menu .sub-menu li a {
        font-size: 14px;
        display: block;
        padding: 12px;
        padding-left: 12px;
        color: #191919;
        transition: .3s;
        padding-left: 30px;
    }

    .main-menu .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none;
        outline: 0;
        cursor: pointer !important;
    }

    .sub-menu::after {
        display: none;
    }

    .hero-area {
        height: 500px;
    }

    .about-content {
        margin-top: 60px;
    }

    p.event-meta {
        font-size: 14px;
    }

    .cta-area h2 {
        font-size: 40px;
        line-height: 60px;
    }

    .main-btn {
        font-size: 14px;
        padding: 12px 18px;
    }

    .main-btn.btn-2 {
        font-size: 14px;
        padding: 12px 18px;
    }

    a.line-btn {
        font-size: 14px;
        padding: 12px 15px;
    }

    .achievement-area {
        height: 400px;
    }

    .single-counter-box h6::before {
        top: 4px;
    }

    .section-absolute-bg {
        display: none;
    }

    .section-bg .offset-6 {
        margin-left: 0;
    }

    .single-team-item {
        padding: 7px 30px;
        margin-top: 20px;
    }

    .team-desc i {
        left: 35px;
    }

    .footer-area {
        text-align: center;
    }

    .footer-area .logo {
        width: 60%;
    }

    p.privacy {
        text-align: center;
        margin: 0;
    }

    .header-area.theme-2 .navigation ul li a {
        color: #222;
    }

    .single-slide-item h1 {
        font-size: 45px;
        line-height: 60px;
    }

    .single-feature-item h4 {
        font-size: 18px;
    }

    .single-feature-item {
        padding: 0;
        margin-bottom: 0;
    }

    .single-feature-item p {
        font-size: 15px;
    }

    .feature-area {
        padding-bottom: 50px;
    }

    .theme-2 .single-feature-item {
        padding: 35px;
        margin-bottom: 40px;
    }

    .social-icon {
        display: none;
    }

    .hero-area.theme-3 .hero-area-content h1 {
        font-size: 65px;
        margin-top: 60px;
    }

    .about-us-bg {
        height: 400px;
        margin-top: 30px;
    }


    .hero-area.theme-3 .hero-area-content h1 {
        font-size: 45px;
        line-height: 65px;
    }

    .cta-area {
        height: 400px;
    }

    .cta-area h2 {
        font-size: 40px;
        line-height: 55px;
    }

    .single-process-area {
        margin-bottom: 50px;
    }

    .single-process-area {
        margin-bottom: 50px;
    }

    .col-lg-12 {
        padding-left: 25px;
        padding-right: 25px;

    }

    .homepage-slides .owl-nav button.owl-prev,
    .homepage-slides .owl-nav button.owl-next {
        display: none;
    }

    .cta-area.theme-2 h2 {
        text-align: center;
    }

    .cta-area.theme-2 .main-btn {
        margin-top: 30px;
    }

    .client-area.section-padding {
        padding: 40px 0;
    }

}

@media only screen and (max-width: 1023px) {
    .main-header .header-lower {
        margin-top: 0;
        position: relative;
        background-color: #333333;
    }

    .main-header .dropdown-menu {
        top: 46px;
    }

    .sticky-header,
    .fixed-header .sticky-header {
        display: none !important;
    }

    .main-header .main-box .logo-box {
        padding-top: 20px;
        width: 100%;
        background: none;
    }

    .main-menu .navigation>li:first-child {
        margin-left: 0;
    }

    .main-header .nav-outer {
        width: 100%;
        padding: 0;
    }

    .header-style-two .main-menu .navigation>li,
    .main-menu .navigation>li {
        padding: 25px 0;
    }

    .main-menu .navigation>li.dropdown>a {
        padding-right: 0px;
    }

    .main-menu .navigation>li.dropdown>a:after,
    .main-header .top-right .lang {
        display: none;
    }

    .nav-outer .search-box-outer {
        padding: 27px 0;
    }

    .main-header .option-box .language {
        padding: 21px 0;
    }

    .main-slider h2 {
        font-size: 40px;
        line-height: 1.2em;
    }

    .sidebar-page-container .comment-form {
        padding-right: 0;
    }


    .about-section .image-column .image-1 img {
        width: 100%;
    }

    .what-we-do .sec-title br,
    .about-section .sec-title br {
        display: none;
    }

    .what-we-do .content-column {
        width: 100%;
    }

    .what-we-do .content-column .inner-box {
        max-width: 100%;
    }

    .what-we-do .image-column {
        position: relative;
        width: 100%;
    }

    .what-we-do .image-column .image-box {
        display: block;
    }

    .subscribe-section .inner-container {
        padding: 30px 30px;
        text-align: center;
    }

    .subscribe-form .form-group {
        float: none;
        margin: 0 auto;
    }

    .donate-form .theme-btn {
        min-width: 100%;
    }

    .work-section.style-two.alternate .work-step,
    .work-section.style-two .work-step {
        margin-top: 0;
    }

    .news-section-two .post-column .inner-column {
        margin-left: 0;
    }

    .donation-and-events .donation-column .inner-column {
        margin-right: 0;
    }

    .main-slider .donate-form {
        padding: 20px 20px 10px;
    }

    .main-slider h4 {
        letter-spacing: 1px;
    }

}

@media only screen and (max-width: 1140px) {

    .about-section .image-column .inner-column,
    .about-section .content-column .inner-column {
        padding-left: 0;
    }

    .about-section .image-column .image-1 {
        text-align: center;
    }

    .about-section .image-column .image-1 img {
        display: inline-block;
    }

    .about-section .image-column .image-2 {
        display: none;
    }

    .about-section-two .progress-column .inner-column,
    .donation-and-events .event-column .inner-column,
    .main-footer .footer-upper .outer-box {
        padding-left: 0;
    }
}

@media only screen and (max-width: 1023px) {

    .about-section .image-column .image-1 img {
        width: 100%;
    }

    .what-we-do .sec-title br,
    .about-section .sec-title br {
        display: none;
    }

}

@media only screen and (min-width: 1140px) {

    .cta-area h2 {
        font-size: 45px;
        line-height: 50px;
    }

}

/*Choose Us CSS */

.choose-us-area {
	background-color: #fff;
}

.choose-us-content {
	padding: 35px;
	margin-top: 10px;
}

.choose-us-bg img {
	border-radius: 10px;
}

.choose-us-content h5 {
	font-size: 30px;
	font-weight: 600;
	margin: 20px 0;
}

.choose-us-content p {
	font-size: 16px;
	line-height: 30px;
}

.single-choose-item i {
	font-size: 40px;
	color: #f81f01;
	float: left;
	border: 2px solid;
	border-radius: 50%;
	padding: 7px;
}

.single-choose-content {
	padding-left: 100px;
	margin: 30px 0;
}

.choose-us-bg {
	margin-top: 40px;
}
